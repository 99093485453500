import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export default function InputValue({inputName, setValue}) {
    // TODO: just do on inputchange
    const handleOnBlur = (e) => {
        setValue(Number(e.target.value));
    };

    return (
        <div>
            <InputGroup className="mb-3">
                <InputGroup.Text>{inputName}:</InputGroup.Text>
                <Form.Control aria-label="Amount (to the nearest dollar)"
                              placeholder={inputName}
                              onBlur={handleOnBlur}
                />
            </InputGroup>
        </div>
    )
}
