import './App.css';
import InputValue from "./components/InputValue";
import {useState} from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import PieChart from "./components/PieChart";
import Header from "./components/Header";

ChartJS.register(ArcElement, Tooltip, Legend);

const TAXABLE_AMT = "Taxable Amount";
const TAX_DEFERRED_AMT = "Tax Deferred Amount";
const TAX_FREE_AMT = "Tax Free Amount";

function App() {
    const [taxableAmt, setTaxableAmt] = useState(0);
    const [taxDeferredAmt, setTaxDeferredAmt] = useState(0);
    const [taxFreeAmt, setTaxFreeAmt] = useState(0);

    let totalAmt = Number(taxableAmt) + Number(taxDeferredAmt) + Number(taxFreeAmt);

    const amountList = [
        { name: TAXABLE_AMT, value: taxableAmt },
        { name: TAX_DEFERRED_AMT, value: taxDeferredAmt },
        { name: TAX_FREE_AMT, value: taxFreeAmt }
    ];

    const [showGraph, setShowGraph] = useState(false)
    console.log(showGraph);
    return (
        <div className="App">
            <Header></Header>
                <br/>
                <InputValue inputName={TAXABLE_AMT}
                            setValue={setTaxableAmt}
                ></InputValue>
                <InputValue inputName={TAX_DEFERRED_AMT}
                            setValue={setTaxDeferredAmt}
                ></InputValue>
                <InputValue inputName={TAX_FREE_AMT}
                            setValue={setTaxFreeAmt}>
                </InputValue>
                <h1>Total Amount: {totalAmt}</h1>

                <button type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        onClick={() => setShowGraph(true)}>
                    Submit

                </button>
                {totalAmt > 0 && showGraph ? <PieChart data={amountList}/> : null}
        </div>
    );
}

export default App;
